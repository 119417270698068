import React, { } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import Vedicvillage from "../../../components/developers/kamandeveloper/our-properties/VedicVillage";

const favicon_logo = require("../../../images/favicon.png");

const KamanPropertyPage = () => (
  <>
    <Favicon url={favicon_logo} />

    <Vedicvillage />
  </>
)

export default KamanPropertyPage
